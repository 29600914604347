<template>
    <b-col cols="12" class="video-info">
        <h1 class="video-title text-color-title">{{ livestream.title[locale] }}</h1>
        <p class="video-date text-color-body"> started streaming on 29 July 2021 </p>
        <p class="video-description text-color-title">
            {{ livestream.description[locale] }}
        </p>
    </b-col>
</template>
<script>
  export default {
    computed: { 
      livestream() {
        return this.$store.getters["Livestreams/active"]
      },
      locale() {
        return this.$root.$i18n.locale.replace(/-/g, "_")
      }
    }
  }
</script>
<style lang="scss">
.video-info {
  position: relative;
  padding-left: 15px !important;
  padding-top: 15px;
  padding-bottom: 15px;
  // margin-top: 15px;
  margin-bottom: 60px;
  max-width: 100%; 
  color: white;
  background-color: rgb(0 0 0 / 40%);
}

.video-date {
  max-width: 50%; 
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid grey;
}

@media only screen and (max-width: 999px) {

  .video-info { 
    margin-top: 0px;
  }

}
</style>