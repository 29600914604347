<template lang="html">
  <li class='tab' v-show='isActive'>
    <font-awesome-icon
      class="label-icon"
      :icon="icon"
    ></font-awesome-icon> <span class="tab-title">{{ title }}</span>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Tab'
    },
    icon: {
      type: String,
      default: 'video'
    }
  },
  data () {
    return {
      isActive: true
    }
  }
}
</script>
<style lang="scss" scoped>
  li {
    padding: 9px 20px;
    cursor: pointer;
  }

  .tab {
    // max-width: 1600px;
  }

  @media only screen and (max-width: 472px) {
  .tab {
    
    padding: 9px 20px;
    margin-left: 1px;
    text-align: center;
  }

  .label-icon {
    position:relative;
    left: 5px;
  }
  .tab-title {
    display: none;
  } 
}
</style>