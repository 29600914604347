<template>
  <div class="stream-chat-container" :class="col">
    <Slido
      :currentUser="currentUser"
      :settings="settings"
      :code="code"
      class="chat"
      v-if="type.toLowerCase() == 'slido'"
    />
    <VimeoChat
      :currentUser="currentUser"
      :code="code"
      class="chat"
      v-if="type.toLowerCase() == 'vimeo'"
    />
    <ArenaChat
      :currentUser="currentUser"
      :code="code"
      class="chat"
      v-if="type.toLowerCase() == 'arena'"
    />
  </div>
</template>
<script>
import VimeoChat from "@/components/chat/VimeoChat.vue";
import Slido from "@/components/chat/Slido.vue";
import ArenaChat from "@/components/chat/ArenaChat.vue";

export default {
  name: "Home",
  props: ["type", "code", "col"],
  components: {
    Slido,
    VimeoChat,
    ArenaChat
  },
  computed: {
    currentUser() { 
      return this.$store.getters["Auth/currentUser"]
    },
    settings() {
      return this.$store.state.Config.setting
    }
  }
}
</script>
<style lang="scss">
.stream-chat-container {
  position: relative;
  width: 100%;
  height: auto;

  .chat {
    height: 100%;
    min-width: 265.455px;
  }
}

@media only screen and (max-width: 999px) {
  .stream-chat-container {
    margin-bottom: 50px;
    // min-height: 750px;
  }
}
</style>