<template>
  <div class="homepage">
    <Tabs :mode="mode"></Tabs>
  </div>
</template>

<script>
import Tabs from "@/components/tabs/Tabs.vue";

export default {
  name: "Livestream",
  components: {
    Tabs,
  },
  async created() {
    await this.$store.dispatch("Livestreams/fetchAll");
  },
  data() {
    return {
      mode: "light",
    };
  },
  computed: {
    settings() {
      return this.$store.state.Config.setting;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
};
</script>

<style lang="scss">
.homepage {
  padding-top: 0px;
  flex-grow: 1;
}
</style>
