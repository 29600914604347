<template lang="html">
  <div :class='{"tabs__light": mode === "light", "tabs__dark": mode === "dark"}'>
    <ul class='tabs__header'>
      <!-- LIVESTREAM TAB -->
      <Tab v-if="livestream.type[locale] !== ''" 
        v-on:click.native="selectTab('livestream')"
        :class='{"tab__selected": ("livestream" == selectedTab)}'
        :title="this.$i18n.t('livestream')" 
        icon="video">
      </Tab>
      <!-- INTERACTIVE TAB -->
      <Tab v-if="settings.interactive_livestream.type[locale] !== ''"
          v-on:click.native='selectTab("interactive"), StopAllAudio()'
          :class='{"tab__selected": ("interactive" == selectedTab)}'
          :title="this.$i18n.t('conversation')"
          icon="comments">
      </Tab>
      <!-- ATTACHMENTS TAB -->
      <Tab v-if="attachments.length !== 0"
          v-on:click.native='selectTab("attachments")'
          :class='{"tab__selected": ("attachments" == selectedTab)}' 
          :title="this.$i18n.t('attachments')" 
          icon="paperclip">
      </Tab>
      <!-- SOCIAL TAB -->
      <Tab 
          v-if="settings.social.type[locale] !== ('' || null)"
          v-on:click.native='selectTab("social")'
          :class='{"tab__selected": ("social" == selectedTab)}' 
          :title="this.$i18n.t('social')"
          icon="hashtag">
      </Tab>
      <ChatQR 
          v-if="chat.type[locale] !== '' && selectedTab == 'livestream'"
          class="qr-button push-right"
          :currentUser="currentUser"
          :settings="settings"
          :chatType="chat.type[locale]"
        />
    </ul>
    <!-- LIVESTREAM CONTENT -->
    <div class="main" v-if="selectedTab == 'livestream'">
      <b-row class="livestream-row">
          <Video/>
          <Chat
            col="col-lg-3"
            :type="chat.type[locale]"
            :code="chat.code[locale]"
          />
      </b-row>
      <b-row>
        <VideoInfo/>
      </b-row>
    </div>
    <!-- INTERACTIVE CONTENT -->
    <div class="main" v-if="selectedTab == 'interactive'">
      <Whereby
      :currentUser="currentUser"
      :settings="settings"
      v-if="(settings.interactive_livestream.type).toLowerCase() == 'whereby'"
      ></Whereby>
    </div>
    <!-- ATTACHMENT CONTENT -->
    <div class="main" v-if="selectedTab == 'attachments'">
      <Attachments></Attachments>
    </div>
    <!-- SOCIAL CONTENT -->
    <div class="main" v-if="selectedTab == 'social'">
      <Instagram/>
    </div>
  </div>
</template>

<script>
import Tab from "@/components/tabs/Tab"
import Video from "@/components/video/Video";
import VideoInfo from "@/components/video/VideoInfo";
import Chat from "@/components/chat/Chat";
import ChatQR from "@/components/chat/ChatQR";
import Whereby from "@/components/interactive-livestream/Whereby.vue";
import Attachments from "@/components/attachments/Attachments";
import Instagram from "@/components/social/Instagram";


export default {
  components: {
    Tab,
    Video,
    VideoInfo,
    Chat,
    Whereby,
    Attachments,
    Instagram,
    ChatQR
  },
  props: {
    mode: {
      type: String,
      default: 'light'
    }
  },
  data () {
    return {
      selectedTab: "livestream", // the index of the selected tab,
    }
  },
  created () {
    this.selectTab('livestream')
  },
  computed: {
    settings() {
      return this.$store.state.Config.setting;
    },
    currentUser() { 
      return this.$store.getters["Auth/currentUser"]
    },
    livestream() {
      return this.$store.getters["Livestreams/active"];
    },
    locale() {
      return this.$root.$i18n.locale.replace(/-/g, "_")
    },
    chat() {
      return this.livestream.chat
    },
    attachments() {
      return this.$store.state.Attachments.attachments
    },
    chatActive() {
      return this.chat.type[this.locale] !== "";
    },
  },
  methods: {
    selectTab(selected) {
      console.log(this.selectedTab)
      this.selectedTab = selected
      console.log(this.selectedTab)
    },
    StopAllAudio() {
      var iframe = this.$el.querySelector("iframe");
      var video = this.$el.querySelector("video");
      if (iframe) {
        var iframeSrc = iframe.src;
        iframe.src = iframeSrc;
      }
      if (video) {
        video.pause();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  $color-primary: var(--primary);
  $color-secondary: var(--secondary);
  $text-color-header: var(--theme-color-text_color_header);
  $text-color-title: var(--theme-color-text_color_title);
  $text-color-body: var(--theme-color-text_color_body);
  $font-family: var(--theme-font-family);
  $background-image: var(--theme-background-image);


  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-left: 0px;
    padding-right: 0px
  }
  ul.tabs__header {
    display: flex;
    align-items: center;
    list-style: none;
    background-color: $color-secondary;
    width: 100%;
    padding-left: 68px;
    padding-right: 68px;
  }

  

  .push-right {
    float: right;
    margin-left: auto;
    right: 0;
  }
  

  .tabs__light .tab{
    // background-color: #fff;
  }

  .tabs__light li {
    color: #fff;
  }

  .tabs__light li.tab__selected {
    background-color: #fff;
    color: #000;
  }

  .tabs__dark .tab{
    background-color: #555;
    color: #eee;
  }

  .tabs__dark li {
    background-color: #ddd;
    color: #aaa;
  }

  .tabs__dark li.tab__selected {
    background-color: #555;
    color: white;
  }

.main {
  max-width: 1600px;
  margin: 0 auto;
  height:100%;
  padding: 0.6923em 2.3077em;
  padding-top: 25px;
  padding-bottom: 0;
}

.livestream-row {
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgb(0 0 0 / 40%);
}

@media only screen and (max-width: 999px) {
  .main {
    padding-top: 15px;
    padding-left: 35px;
    padding-right: 35px;
    margin: 0 0;
  }

  .chat {padding-top: 25px;}
}

@media only screen and (max-width: 750px) {
  .qr-button { 
    display: none;
  }
  .nav {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 556px) {
  .tab-title {
    display: none;
  } 

  .nav-tabs, ul.tabs__header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main {
    padding-top: 15px;
  }
}

</style>