<template>
  <div
    class="video-player"
    :class="{ 'col-lg-9': chatActive, 'col-lg-12': !chatActive }"
  >
    <div class="video-player-container">
    <Vimeo
      class="video"
      :currentUser="currentUser"
      :code="livestream.code[locale]"
      :chatActive="chatActive"
      v-if="(livestream.type[locale]).toLowerCase() == 'vimeo'"
    />
    <Zoom
      class="video"
      v-if="(livestream.type[locale]).toLowerCase() == 'zoom'"
    />
    <Youtube
      class="video"
      :chatActive="chatActive"
      :code="livestream.code[locale]"
      v-if="(livestream.type[locale]).toLowerCase() == 'youtube'"
    />
    </div>
  </div>
</template>
<script>
import Zoom from "@/components/video/Zoom.vue";
import Youtube from "@/components/video/Youtube.vue";
import Vimeo from "@/components/video/Vimeo.vue";

export default {
  name: "Home",
  components: {
    Vimeo,
    Zoom,
    Youtube,
  },
  computed: {
    livestream() {
      return this.$store.getters["Livestreams/active"];
    },
    settings() {
      return this.$store.state.Config.setting;
    },
    locale() {
      return this.$root.$i18n.locale.replace(/-/g, "_")
    },
    chatActive() {
      return this.livestream.chat.type[this.locale] !== "";
    },
    currentUser() { 
      return this.$store.getters["Auth/currentUser"]
    }
  },
}

</script>
<style lang="scss" scoped>
.video-player-container {
  padding-left: 0px !important;
  padding-right: 0px;
  padding-top: 56.25%;
  width: 100%;
  height: auto;
  
  .video {
    padding-left: 0px !important;
    padding-right: 0px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

}

@media only screen and (max-width: 999px) {
  .video-player-container {
    width: 100%;
    padding-top: 75%;
  }
}

</style>