<template>
  <iframe
    :src="`https://vimeo.com/event/${code}`"
    id="vimeo-iframe"
    frameborder="0"
    allow="autoplay; fullscreen;"
    allowfullscreen
    data-vimeo-tracked="true"
    :class="{'full-height': !chatActive}"
  ></iframe>
</template>
<script>
export default {
  name: "Vimeo",
  props: ["code", "chatActive"]
};
</script>
